/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../media/fonts/Inter/static/Inter-Regular.woff2') format('woff2'), url('../media/fonts/Inter/static/Inter-Regular.woff') format('woff'),
    url('../media/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
}

/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../media/fonts/Inter/static/Inter-Bold.woff2') format('woff2'), url('../media/fonts/Inter/static/Inter-Bold.woff') format('woff'),
    url('../media/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
}

/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../media/fonts/Inter/static/Inter-Medium.woff2') format('woff2'), url('../media/fonts/Inter/static/Inter-Medium.woff') format('woff'),
    url('../media/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
}

/* LATO */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../media/fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../media/fonts/Lato/Lato-Bold.ttf') format('truetype');
}
// @font-face {
//   font-family: 'Lato';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('../media/fonts/Lato/Lato-Medium.ttf') format('truetype');
// }

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../media/fonts/Quicksand/static/Quicksand-Light.ttf') format('truetype');
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../media/fonts/Quicksand/static/Quicksand-Regular.ttf') format('truetype');
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../media/fonts/Quicksand/static/Quicksand-Bold.ttf') format('truetype');
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../media/fonts/Quicksand/static/Quicksand-Medium.ttf') format('truetype');
}
