@import './styles/variables';
@import './styles/fonts';

html,
body {
  height: 100%;
}
div#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
img {
  max-width: 100%;
  height: auto;
}

a,
a:focus,
a:visited {
  color: inherit;
  text-decoration: none;
}

.gradient-button {
  background: #184590;
  // background: transparent linear-gradient(247deg, $PVDpurple 0%, $PVDblue 100%) 0% 0% no-repeat padding-box;
  color: white;
}
.vertical_gradient-button {
  background: #184590;
  // background: transparent linear-gradient(0deg, $PVDpurple 0%, $PVDblue 100%) 0% 0% no-repeat padding-box;
  color: white;
}

nav,
.nav-item {
  a.active button {
    @extend .gradient-button;
  }
}
nav {
  $navItemSpace: 1.5rem;
  .nav-item:not(:first-child) {
    padding-left: $navItemSpace;
  }
  .nav-item:not(:last-child) {
    padding-right: $navItemSpace;
    border-right: 1px solid $PVDgray;
  }
}
#menu-appbar {
  .nav-item.fullwidth {
    width: 100%;
    a,
    a button {
      width: 100%;
    }
  }
}

.bordered {
  border: 1px solid #666666 !important;
}
.input-border-radius {
  border-radius: 0 !important;
}

footer {
  .footer__link {
    color: $PVDgray;
    &:not(:first-child) {
      padding-left: 1rem;
    }
    &:not(:last-child) {
      padding-right: 1rem;
      border-right: 1px solid $PVDgray;
    }
  }
}

.main_background {
  // background: transparent linear-gradient(233deg, $PVDblue 0%, $PVDpurple 100%) 0% 0% no-repeat padding-box;
  background-color: white;
}

.dotted {
  $dotspace: 10px;
  $dotwidth: 1px;
  height: $dotwidth;
  --dot-color: #494d7a; //rgba(255, 255, 255, 1);
  background: var(--dot-color);
  // background: repeating-linear-gradient(to right, var(--dot-color) 0, var(--dot-color) $dotwidth, rgba(255, 255, 255, 0) $dotwidth, rgba(255, 255, 255, 0) ($dotwidth + $dotspace));

  &.black {
    --dot-color: rgba(0, 0, 0, 1);
  }

  &.purple {
    --dot-color: #{$PVDpurple};
  }
}

.rounded-corner {
  border-radius: 1rem;
  overflow: hidden;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

// .tab_menu__list_item {
//   &:before {
//     content: '\2022';
//   }
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.confirm-dialog-box {
  background-color: white;
}

// question
p.question-title,
label.question-title {
  font-weight: 700;
}
